import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 30,
  },
  path: {
    fill: '#7df3e1',
  },
});
const LogoFull = () => {
  const classes = useStyles();

  return (
    <svg
      className={classes.svg}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 431.33 88.85"
    >
      <path
        d="M126.89,81.81h0a8.8,8.8,0,0,1-8.8-8.81V38.66a8.81,8.81,0,0,1,8.8-8.81h0a8.81,8.81,0,0,1,8.8,8.81V73A8.8,8.8,0,0,1,126.89,81.81Z"
        transform="translate(-26.78 -26.51)"
        fill="#44ace2"
      />
      <path
        d="M35.58,81.81h0A8.8,8.8,0,0,1,26.78,73V38.66a8.81,8.81,0,0,1,8.8-8.81h0a8.82,8.82,0,0,1,8.81,8.81V73A8.81,8.81,0,0,1,35.58,81.81Z"
        transform="translate(-26.78 -26.51)"
        fill="#44ace2"
      />
      <path
        d="M104.85,95.6h0a8.8,8.8,0,0,1-8.8-8.8V52.45a8.8,8.8,0,0,1,8.8-8.8h0a8.81,8.81,0,0,1,8.81,8.8V86.8A8.81,8.81,0,0,1,104.85,95.6Z"
        transform="translate(-26.78 -26.51)"
        fill="#e82126"
      />
      <path
        d="M81.24,78.46h0a8.8,8.8,0,0,1-8.81-8.8V35.31a8.8,8.8,0,0,1,8.81-8.8h0a8.8,8.8,0,0,1,8.8,8.8V69.66A8.8,8.8,0,0,1,81.24,78.46Z"
        transform="translate(-26.78 -26.51)"
        fill="#f7b319"
      />
      <path
        d="M58.78,98.16h0a8.8,8.8,0,0,1-8.8-8.8V55a8.8,8.8,0,0,1,8.8-8.8h0A8.81,8.81,0,0,1,67.59,55V89.36A8.81,8.81,0,0,1,58.78,98.16Z"
        transform="translate(-26.78 -26.51)"
        fill="#2a9f48"
      />
      <path
        d="M154.11,79.26a3,3,0,0,1-3-3V37.37a2.83,2.83,0,0,1,.87-2.15,2.91,2.91,0,0,1,2.12-.84h13.73a14.74,14.74,0,0,1,6.87,1.53,11.65,11.65,0,0,1,4.65,4.22,11.43,11.43,0,0,1,1.7,6.2,9,9,0,0,1-1.52,5.09A10.69,10.69,0,0,1,175.37,55a11.19,11.19,0,0,1,6.09,3.62,10.55,10.55,0,0,1,2.3,7.07,12.83,12.83,0,0,1-7.07,11.8,16.15,16.15,0,0,1-7.64,1.76Zm3-26.43h10.74a8.61,8.61,0,0,0,5.38-1.61,5.79,5.79,0,0,0,2.09-4.89,5.69,5.69,0,0,0-2.09-4.85,8.72,8.72,0,0,0-5.38-1.58H157.1Zm0,20.91h12a11.12,11.12,0,0,0,4.65-.92,7.19,7.19,0,0,0,3.16-2.7A8,8,0,0,0,178,65.7a6.81,6.81,0,0,0-1.15-4.08,7.07,7.07,0,0,0-3.16-2.44,12,12,0,0,0-4.65-.84h-12Z"
        transform="translate(-26.78 -26.51)"
        fill="#fff"
      />
      <path
        d="M205.19,79.49a13.82,13.82,0,0,1-7.53-2.1,14.85,14.85,0,0,1-5.25-5.69,16.84,16.84,0,0,1-1.93-8.13,16,16,0,0,1,2.1-8.16,15.43,15.43,0,0,1,5.69-5.71,15.67,15.67,0,0,1,8.07-2.1A15.48,15.48,0,0,1,220,55.41a16,16,0,0,1,2.1,8.16H219.9A16.84,16.84,0,0,1,218,71.7a14.85,14.85,0,0,1-5.25,5.69A13.82,13.82,0,0,1,205.19,79.49Zm1.15-5.17a9.8,9.8,0,0,0,5.23-1.41,10,10,0,0,0,3.62-3.85,12.18,12.18,0,0,0,0-11,10,10,0,0,0-3.62-3.85,10.35,10.35,0,0,0-10.43,0A10.16,10.16,0,0,0,197.46,58a11.94,11.94,0,0,0,0,11,10.16,10.16,0,0,0,3.68,3.85A9.82,9.82,0,0,0,206.34,74.32Zm12.87,5a2.84,2.84,0,0,1-2.93-2.93V67.59l1.09-6.09,4.77,2.07V76.38a2.84,2.84,0,0,1-2.93,2.93Z"
        transform="translate(-26.78 -26.51)"
        fill="#fff"
      />
      <path
        d="M245.12,79.49a16,16,0,0,1-8.19-2.1,15.15,15.15,0,0,1-5.63-5.69,16.15,16.15,0,0,1-2-8.13,16.64,16.64,0,0,1,2-8.27,14.56,14.56,0,0,1,5.51-5.66,15.7,15.7,0,0,1,8-2A14.75,14.75,0,0,1,251,48.89a15,15,0,0,1,5.06,3.88,2.09,2.09,0,0,1,.57,1.9,3,3,0,0,1-1.26,1.72,2.17,2.17,0,0,1-1.78.37,3.51,3.51,0,0,1-1.79-1,9.23,9.23,0,0,0-7-3,9.84,9.84,0,0,0-5.17,1.35,9.35,9.35,0,0,0-3.5,3.76,12.15,12.15,0,0,0-1.26,5.69,11.53,11.53,0,0,0,1.29,5.52,9.85,9.85,0,0,0,3.59,3.82,10,10,0,0,0,5.34,1.41,11.83,11.83,0,0,0,3.54-.49,8.52,8.52,0,0,0,2.78-1.53,3.6,3.6,0,0,1,1.9-.83,2.26,2.26,0,0,1,1.72.55,2.71,2.71,0,0,1,1.09,1.81,2.11,2.11,0,0,1-.69,1.81A14.6,14.6,0,0,1,245.12,79.49Z"
        transform="translate(-26.78 -26.51)"
        fill="#fff"
      />
      <path
        d="M268.22,79.26a2.68,2.68,0,0,1-2.82-2.82V37.2a2.82,2.82,0,1,1,5.64,0V76.44a2.68,2.68,0,0,1-2.82,2.82Zm2.7-11.49-3.56-3.91L285,48.12a2.5,2.5,0,0,1,3.73.17,2.47,2.47,0,0,1,.75,1.89,2.69,2.69,0,0,1-.92,1.84ZM288.73,79a2.77,2.77,0,0,1-2,.66,2.56,2.56,0,0,1-1.86-1L273.16,64.2l4-3.79,11.95,14.71a2.68,2.68,0,0,1,.66,2A2.65,2.65,0,0,1,288.73,79Z"
        transform="translate(-26.78 -26.51)"
        fill="#fff"
      />
      <path
        d="M309.53,79.49a21.59,21.59,0,0,1-7.44-1.24,14.2,14.2,0,0,1-5.31-3.13,2.47,2.47,0,0,1-.72-2.1,2.75,2.75,0,0,1,1.12-1.92,3,3,0,0,1,2.27-.78,3.13,3.13,0,0,1,1.92,1,9.1,9.1,0,0,0,3.13,2,11.85,11.85,0,0,0,4.8.95,9.69,9.69,0,0,0,5.09-1.1,3.38,3.38,0,0,0,1.81-2.81,3.53,3.53,0,0,0-1.64-3q-1.69-1.26-6.18-2.07c-3.87-.76-6.67-1.91-8.42-3.45a7.2,7.2,0,0,1-2.61-5.63,7,7,0,0,1,1.72-4.85,10.49,10.49,0,0,1,4.43-2.87,16.64,16.64,0,0,1,5.63-.95,17.59,17.59,0,0,1,6.72,1.2,11.45,11.45,0,0,1,4.65,3.34,2.62,2.62,0,0,1,.75,1.92,2.24,2.24,0,0,1-1,1.7,2.86,2.86,0,0,1-2.19.4,4,4,0,0,1-2.12-1,7.91,7.91,0,0,0-3.11-1.89,12.81,12.81,0,0,0-3.85-.52,9.72,9.72,0,0,0-4.28.86A2.73,2.73,0,0,0,303,56.1a3.31,3.31,0,0,0,.55,1.87,4.6,4.6,0,0,0,2.15,1.49,28.29,28.29,0,0,0,4.71,1.3,25.35,25.35,0,0,1,6.81,2.18A9,9,0,0,1,320.79,66a7.49,7.49,0,0,1,1.09,4,8.4,8.4,0,0,1-1.4,4.77,9.66,9.66,0,0,1-4.14,3.39A16.08,16.08,0,0,1,309.53,79.49Z"
        transform="translate(-26.78 -26.51)"
        fill="#fff"
      />
      <path
        d="M328,53.92a2.77,2.77,0,0,1-2-.69,2.26,2.26,0,0,1-.75-1.73,2.37,2.37,0,0,1,.75-1.83,2.77,2.77,0,0,1,2-.69h13.28a2.77,2.77,0,0,1,1.95.69A2.37,2.37,0,0,1,344,51.5a2.26,2.26,0,0,1-.75,1.73,2.77,2.77,0,0,1-1.95.69Zm12.76,25.34a9.37,9.37,0,0,1-5.17-1.47,10.34,10.34,0,0,1-3.62-4,12.11,12.11,0,0,1-1.32-5.69V40.7a2.8,2.8,0,0,1,.8-2.07,2.87,2.87,0,0,1,4.94,2.07V68.11A5.86,5.86,0,0,0,337.63,72a3.86,3.86,0,0,0,3.1,1.55h1.95a2.27,2.27,0,0,1,1.76.81,3,3,0,0,1,.71,2.06,2.57,2.57,0,0,1-.94,2.07,3.66,3.66,0,0,1-2.45.81Z"
        transform="translate(-26.78 -26.51)"
        fill="#fff"
      />
      <path
        d="M365.44,79.49a13.82,13.82,0,0,1-7.53-2.1,15,15,0,0,1-5.26-5.69,17,17,0,0,1-1.92-8.13,16.09,16.09,0,0,1,2.09-8.16,15.51,15.51,0,0,1,5.69-5.71,15.73,15.73,0,0,1,8.08-2.1,15.48,15.48,0,0,1,13.7,7.81,16,16,0,0,1,2.1,8.16h-2.24a17,17,0,0,1-1.93,8.13A14.87,14.87,0,0,1,373,77.39,13.81,13.81,0,0,1,365.44,79.49Zm1.15-5.17a9.77,9.77,0,0,0,5.22-1.41,9.94,9.94,0,0,0,3.62-3.85,12.1,12.1,0,0,0,0-11,9.94,9.94,0,0,0-3.62-3.85,10.33,10.33,0,0,0-10.42,0A10.09,10.09,0,0,0,357.71,58a11.94,11.94,0,0,0,0,11,10.09,10.09,0,0,0,3.68,3.85A9.82,9.82,0,0,0,366.59,74.32Zm12.87,5a2.84,2.84,0,0,1-2.93-2.93V67.59l1.09-6.09,4.77,2.07V76.38a2.85,2.85,0,0,1-2.93,2.93Z"
        transform="translate(-26.78 -26.51)"
        fill="#fff"
      />
      <path
        d="M404.22,79.49a14,14,0,0,1-12.81-7.67,17.37,17.37,0,0,1-1.9-8.25,16.5,16.5,0,0,1,2-8.3,14.84,14.84,0,0,1,5.6-5.63,17.46,17.46,0,0,1,16.38,0,14.84,14.84,0,0,1,5.6,5.63,16.5,16.5,0,0,1,2,8.3h-3.39A18.73,18.73,0,0,1,416,71.82a14,14,0,0,1-4.8,5.63A12.31,12.31,0,0,1,404.22,79.49Zm1,12.81a18.4,18.4,0,0,1-7.64-1.61,16,16,0,0,1-6-4.71,2.45,2.45,0,0,1-.55-2,2.59,2.59,0,0,1,1.12-1.67,2.78,2.78,0,0,1,2.16-.46A3,3,0,0,1,396.12,83,10.6,10.6,0,0,0,400,86a12.72,12.72,0,0,0,5.35,1.09,10.1,10.1,0,0,0,5.08-1.32,9.54,9.54,0,0,0,3.71-3.91,13.27,13.27,0,0,0,1.38-6.32V67.42l1.89-5.06,3.74,1.21V76a17,17,0,0,1-2,8.25,15.39,15.39,0,0,1-5.6,5.89A15.75,15.75,0,0,1,405.25,92.3Zm.12-18a10,10,0,0,0,5.28-1.38,9.68,9.68,0,0,0,3.6-3.8,11.6,11.6,0,0,0,1.29-5.57,11.8,11.8,0,0,0-1.29-5.6,9.61,9.61,0,0,0-3.6-3.82,10.81,10.81,0,0,0-10.57,0A9.79,9.79,0,0,0,396.46,58a11.58,11.58,0,0,0-1.32,5.6,11.38,11.38,0,0,0,1.32,5.57,9.88,9.88,0,0,0,3.62,3.8A10.08,10.08,0,0,0,405.37,74.32Z"
        transform="translate(-26.78 -26.51)"
        fill="#fff"
      />
      <path
        d="M444.67,79.49a17.3,17.3,0,0,1-8.48-2,15,15,0,0,1-5.8-5.63,16.07,16.07,0,0,1-2.1-8.25,16.79,16.79,0,0,1,2-8.3,14.54,14.54,0,0,1,5.48-5.63,15.72,15.72,0,0,1,8.05-2,14.44,14.44,0,0,1,7.7,2A12.93,12.93,0,0,1,456.42,55a18.07,18.07,0,0,1,1.69,8,2.41,2.41,0,0,1-.74,1.81,2.63,2.63,0,0,1-1.9.72H432.31V61h23l-2.36,1.6a12.32,12.32,0,0,0-1.15-5.14,8.37,8.37,0,0,0-8-4.91A10.47,10.47,0,0,0,438.2,54a9.2,9.2,0,0,0-3.53,3.93,12.92,12.92,0,0,0-1.21,5.66,10.94,10.94,0,0,0,1.44,5.63,10.5,10.5,0,0,0,4,3.91,11.47,11.47,0,0,0,5.8,1.44,10.75,10.75,0,0,0,3.65-.67,11.1,11.1,0,0,0,3-1.52,3.29,3.29,0,0,1,1.87-.66,2.49,2.49,0,0,1,1.75.6,2.76,2.76,0,0,1,1,1.9,2.06,2.06,0,0,1-.92,1.78,15.68,15.68,0,0,1-4.86,2.53A17.37,17.37,0,0,1,444.67,79.49Z"
        transform="translate(-26.78 -26.51)"
        fill="#fff"
      />
      <path
        d="M404.64,106.83l-3.22-5.26a1,1,0,0,0-1.05-.57h-.59v-2h1.34a2,2,0,0,1,2.14,1.23l1.79,3c.5.89,1,1.89,1,1.89h0s.45-1,1-1.89l1.82-3a2,2,0,0,1,2.15-1.23h1.32v2h-.57a1,1,0,0,0-1.07.57l-3.24,5.26,5.07,8.26H410l-2.91-4.89c-.53-.89-1-1.82-1-1.82h0s-.45,1-1,1.84l-2.9,4.87H399.6Z"
        transform="translate(-26.78 -26.51)"
        fill="#fff"
      />
      <path
        d="M414.17,107.64h6.63v1.94h-6.63Z"
        transform="translate(-26.78 -26.51)"
        fill="#fff"
      />
      <path
        d="M423,101.5a.66.66,0,0,0-.75-.5H422v-2h1.27c1.14,0,1.53.25,1.89,1.32l3.52,9.88c.3.87.62,2.21.62,2.21h0c.17-.75.37-1.48.61-2.21l3.53-9.88c.38-1.07.75-1.32,1.88-1.32h1.28v2h-.32c-.41,0-.62.09-.75.5l-5,13.59h-2.42Z"
        transform="translate(-26.78 -26.51)"
        fill="#fff"
      />
      <path
        d="M438.91,106a.44.44,0,0,0-.38-.5h-1V103.6h2.16c1,0,1.46.43,1.46,1.45v7.66a.44.44,0,0,0,.38.5h1v1.88h-2.16c-1,0-1.46-.43-1.46-1.46Zm0-6.93h2v2.29h-2Z"
        transform="translate(-26.78 -26.51)"
        fill="#fff"
      />
      <path
        d="M450.89,107.93h.5v-.11c0-2-.75-2.76-2.55-2.76-.55,0-1.88.14-1.88.89v.66h-2.07v-1.13c0-1.88,2.86-2.16,4-2.16,3.73,0,4.73,2,4.73,4.5v4.89a.45.45,0,0,0,.38.5H455v1.88H453c-1,0-1.43-.5-1.43-1.32a5.06,5.06,0,0,1,0-.68h0a3.92,3.92,0,0,1-3.66,2.27c-2,0-3.91-1.2-3.91-3.5C444,108.16,449,107.93,450.89,107.93Zm-2.54,5.66c1.86,0,3.06-2,3.06-3.66v-.39h-.56c-1.66,0-4.66.1-4.66,2.21a1.91,1.91,0,0,0,2.16,1.85Z"
        transform="translate(-26.78 -26.51)"
        fill="#fff"
      />
    </svg>
  );
};

export default LogoFull;
